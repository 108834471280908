import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Layout, Menu } from "antd";
import {
  SolutionOutlined,
  DashboardOutlined,
  SmileOutlined,
  TeamOutlined,
  ProfileOutlined,
  AppstoreOutlined,
  PictureOutlined,
  MailOutlined,
  MessageOutlined,
  LikeOutlined,
  FileTextOutlined,
  FileSearchOutlined,
  FileOutlined,
} from "@ant-design/icons";
import { UserContext } from "../../context/UserContext";

const { Sider } = Layout;

const AppSidebar = ({ collapsed, onCollapse }) => {
  const { user } = useContext(UserContext);


  

  const [activeMenu, setActiveMenu] = useState("/app/dashboard");

  const history = useHistory();
  let location = useLocation();

  const handleClick = ({ key }) => {
    setActiveMenu(key);
    history.push(key);
  };

  useEffect(() => {
    setActiveMenu(location.pathname);
  }, []);

  return (
    <Sider 
    trigger={null}
    collapsible
    collapsed={collapsed}
    width={220} // sidebar-ის სრული სიგანე
    collapsedWidth={0} // ბოლომდე დაკეტვა
    theme="light" // აქ დავაყენეთ თეთრი თემა
    onCollapse={onCollapse}>
      <div className="logo">
        {collapsed ? (
          <img
            src="https://test.app.yrc.ge/logo.png"
            style={{ width: 35, alignItems: "center" }}
            alt="yrc"
          />
        ) : (
          <img
            src="https://test.app.yrc.ge/logo.png"
            style={{ width: 70, alignItems: "center" }}
            alt="yrc"
          />
        )}
      </div>
      <Menu
        onClick={handleClick}
        theme="light"
        mode="inline"
        selectedKeys={[activeMenu]}
        defaultSelectedKeys={[activeMenu]}
      >
        {user?.role === "commission" ?

          <>
            <Menu.Item key="/" icon={<SolutionOutlined />}>
              ღონისძიებები
            </Menu.Item>
          </>
          :

          (<>
            <Menu.Item key="/app/dashboard" icon={<DashboardOutlined />}>
              მთავარი
            </Menu.Item>
            <Menu.Item key="/app/approve" icon={<SmileOutlined />}>
              ვერიფიკაცია
            </Menu.Item>
            <Menu.Item key="/app/events" icon={<SolutionOutlined />}>
              ივენთები
            </Menu.Item>
            <Menu.Item key="/app/subCategories" icon={<ProfileOutlined />}>
              ქვეკატეგორიები
            </Menu.Item>
            <Menu.Item key="/app/users" icon={<TeamOutlined />}>
              მომხმარებლები
            </Menu.Item>
            <Menu.Item key="/app/news" icon={<AppstoreOutlined />}>
              სიახლეები
            </Menu.Item>
            <Menu.Item key="/app/galleries" icon={<PictureOutlined />}>
              გალერეა
            </Menu.Item>
            <Menu.Item key="/app/mail" icon={<MailOutlined />}>
              EMAL
            </Menu.Item>
            <Menu.Item key="/app/sms" icon={<MessageOutlined />}>
              SMS
            </Menu.Item>
            <Menu.Item key="/app/commission" icon={<LikeOutlined />}>
              კომისია
            </Menu.Item>
            <Menu.Item key="/app/blog" icon={<FileOutlined />}>
              ბლოგი
            </Menu.Item>
            <Menu.Item key="/app/public" icon={<FileTextOutlined />}>
              საჯარო ინფორმაცია
            </Menu.Item>
            <Menu.Item key="/app/logs" icon={<FileSearchOutlined />}>
              LOG
            </Menu.Item>
          </>)}
      </Menu>
    </Sider>
  );
};

export default AppSidebar;
