import React from "react";

import { ApolloClient, InMemoryCache, split, from } from "@apollo/client";
import { ApolloProvider } from "@apollo/client/react";

import { createUploadLink } from "apollo-upload-client";
// import { useAuth } from "../context/AuthProvider";

// const END_POINT = "localhost:4000/graphql";
const END_POINT = "yrcapi-46879e10c9e4.herokuapp.com/graphql";

const ApolloProviderWrapper = ({ children }) => {
  const token = localStorage.getItem("adtoken");
  const uploadLinkOptions = {
    uri: `https://${END_POINT}`,
    headers: {
      authorization: token ? `Bearer ${token}` : null,
      "keep-alive": "true",
    },
  };

  const uploadLink = createUploadLink(uploadLinkOptions);

  const splitLink = from([uploadLink]);

  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloProviderWrapper;
